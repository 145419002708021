@import "./assets/icons/style.css";

@font-face {
  font-family: "Titillium Web-Bold";
    src: url("./assets/fonts/TitilliumWeb-Bold.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Titillium Web";
  src: url("./assets/fonts/TitilliumWeb-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Titillium Web-Light";
  src: url("./assets/fonts/TitilliumWeb-Light.ttf");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu-Bold";
    src: url("./assets/fonts/Ubuntu-Bold.woff2") format("woff2"),
      url("./assets/fonts/Ubuntu-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu-Light";
  src: url("./assets/fonts/Ubuntu-Light.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu-Italic";
  src: url("./assets/fonts/Ubuntu-Italic.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("./assets/fonts/Ubuntu-Regular.woff2") format("woff2"),
    url("./assets/fonts/Ubuntu-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Ubuntu-Medium";
    src: url("./assets/fonts/Ubuntu-Medium.woff2") format("woff2"),
      url("./assets/fonts/Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Titillium-Web";
  src: url("./assets/fonts/TitilliumWeb-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu-Regular", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}