@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?9rgtk1');
  src: url('fonts/icomoon.eot?9rgtk1#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?9rgtk1') format('truetype'),
    url('fonts/icomoon.woff?9rgtk1') format('woff'),
    url('fonts/icomoon.svg?9rgtk1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e901";
  color: #fff;
}

.icon-arrow-up:before {
  content: "\e902";
  color: #ebecf0;
}

.icon-cash:before {
  content: "\e903";
  color: #7f63f4;
}

.icon-close:before {
  content: "\e904";
  color: #ebecf0;
}

.icon-copy:before {
  content: "\e905";
  color: #ebecf0;
}

.icon-crypto:before {
  content: "\e906";
  color: #7f63f4;
}

.icon-delete:before {
  content: "\e907";
  color: #ebecf0;
}

.icon-documentation:before {
  content: "\e908";
  color: #7f63f4;
}

.icon-edit:before {
  content: "\e909";
  color: #ebecf0;
}

.icon-email:before {
  content: "\e90a";
  color: #7f63f4;
}

.icon-error:before {
  content: "\e90b";
  color: #de4949;
}

.icon-faq:before {
  content: "\e90c";
  color: #7f63f4;
}

.icon-get-in-touch:before {
  content: "\e90d";
  color: #7f63f4;
}

.icon-hide-menu:before {
  content: "\e90e";
  color: #606983;
}

.icon-home:before {
  content: "\e90f";
  color: #fff;
}

.icon-mode:before {
  content: "\e910";
  color: #384464;
}

.icon-no-data:before {
  content: "\e911";
  color: #7f63f4;
}

.icon-no-data2:before {
  content: "\e912";
  color: #7f63f4;
}

.icon-no-data3:before {
  content: "\e913";
  color: #7f63f4;
}

.icon-notification:before {
  content: "\e914";
  color: #ebecf0;
}

.icon-reports:before {
  content: "\e915";
  color: #606983;
}

.icon-reset-password:before {
  content: "\e916";
  color: #7f63f4;
}

.icon-search:before {
  content: "\e917";
  color: #ebecf0;
}

.icon-security-settings:before {
  content: "\e918";
  color: #b8a7ff;
}

.icon-settings:before {
  content: "\e919";
  color: #606983;
}

.icon-star:before {
  content: "\e91a";
  color: #9ba1b1;
}

.icon-success:before {
  content: "\e91b";
  color: #4ca250;
}

.icon-success2:before {
  content: "\e91c";
  color: #7f63f4;
}

.icon-support:before {
  content: "\e91d";
  color: #606983;
}

.icon-trading:before {
  content: "\e91e";
  color: #606983;
}

.icon-trading2:before {
  content: "\e91f";
  color: #b8a7ff;
}

.icon-trading3:before {
  content: "\e920";
  color: #ebecf0;
}

.icon-two-factor-authentication:before {
  content: "\e921";
  color: #7f63f4;
}

.icon-user:before {
  content: "\e922";
  color: #ebecf0;
}

.icon-wallet:before {
  content: "\e923";
  color: #606983;
}

.icon-warning:before {
  content: "\e924";
  color: #ebecf0;
}

.icon-whitelist-addresses:before {
  content: "\e925";
  color: #7f63f4;
}

.icon-close2:before {
  content: "\e926";
  color: #7f63f4;
}

.icon-toggle-nav:before {
  content: "\e927";
  color: #7f63f4;
}

.icon-log-out:before {
  content: "\e928";
  color: #c3c7d1;
}

.icon-trading-change:before {
  content: "\e929";
  color: #9ba1b1;
}

.icon-back-to:before {
  content: "\e92a";
  color: #ebecf0;
}

.icon-copy2:before {
  content: "\e92b";
  color: #ebecf0;
}

.icon-info:before {
  content: "\e92c";
  color: #ebecf0;
}

.icon-open-pane:before {
  content: "\e92d";
  color: #ebecf0;
}

.icon-open-pane2:before {
  content: "\e92e";
  color: #ebecf0;
}

.icon-star2:before {
  content: "\e92f";
  color: #ebecf0;
}

.icon-zoom:before {
  content: "\e930";
  color: #384464;
}

.icon-api-settings:before {
  content: "\e900";
  color: #b8a7ff;
}

.icon-checkmark:before {
  content: "\e931";
  color: #fff;
}

.icon-sand-timer:before {
  content: "\e932";
  color: #7f63f4;
}

.icon-error1:before {
  content: "\e933";
  color: #de4949;
}

.icon-medal:before {
  content: "\e934";
  color: #7f63f4;
}

.icon-visibility-off:before {
  content: "\e935";
  color: #ebecf0;
}

.icon-visibility:before {
  content: "\e936";
  color: #ebecf0;
}

.icon-eye:before {
  content: "\e937";
  color: #384464;
}

.icon-visibility-light:before {
  content: "\e938";
  color: #606983;
}

.icon-favorite .path1:before {
  content: "\e939";
  color: rgb(255, 212, 34);
}

.icon-favorite .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(238, 191, 0);
}